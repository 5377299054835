import React from "react";

import {
  FaRegFile,
  FaRegFileAudio,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileVideo,
  FaRegFileWord,
  FaYoutube,
} from "react-icons/fa";

const AssetIcon = (type) => {
  const defaultIcon = <FaRegFile style={{ fill: "#ffba2a" }} />;
  if (typeof type != "string") return defaultIcon;
  switch (type.toLowerCase()) {
    case "youtube":
      return <FaYoutube style={{ fill: "#db3a14" }} />;
    case "pdf":
      return <FaRegFilePdf style={{ fill: "#db3a14" }} />;
    case "doc":
    case "docx":
      return <FaRegFileWord style={{ fill: "#185abd" }} />;
    case "xls":
    case "xlsx":
      return <FaRegFileExcel style={{ fill: "#107a40" }} />;
    case "ppt":
    case "pptx":
      return <FaRegFilePowerpoint style={{ fill: "#c43f1d" }} />;
    case "video":
      return <FaRegFileVideo style={{ fill: "#3f238f" }} />;
    case "audio":
      return <FaRegFileAudio style={{ fill: "#9b4a85" }} />;
    default:
      return defaultIcon;
  }
};

export default function ChapterDownload({ chapter }) {
  // console.log(chapter);
  return (
    <section className="section">
      <h4 className="title">{chapter.title}</h4>
      <div className="is-flex is-align-content-center is-justify-content-center">
        <span className="mr-2">初中</span>
        {chapter.types.map((type) => (
          <a
            className="mr-2"
            href={`${process.env.REACT_APP_ASSETS_BASE}${chapter.files[0]}.${type}`}
            key={type}
            target="_blank"
            rel="noopener noreferrer"
          >
            {AssetIcon(type)}
            {type.toUpperCase()}
          </a>
        ))}
      </div>
      <div>
        <span className="mr-2">高小</span>
        {chapter.types.map((type) => (
          <a
            className="mr-2"
            href={`${process.env.REACT_APP_ASSETS_BASE}${chapter.files[1]}.${type}`}
            key={type}
            target="_blank"
            rel="noopener noreferrer"
          >
            {AssetIcon(type)}
            {type.toUpperCase()}
          </a>
        ))}
      </div>
    </section>
  );
}
