import React from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";
import ScrollToTop from "components/ScrollToTop";

import routes from "routes";

import "App.scss";

function App() {
  return (
    <div className="App container is-widescreen has-text-centered">
      <HashRouter>
        <Header />
        <ScrollToTop />

        <Switch>
          {routes.map((route) => (
            <Route
              exact={route.exact}
              path={route.path}
              component={route.children}
              key={route.path}
            />
          ))}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>

        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
