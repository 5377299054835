import innerText from "react-innertext";

import "./Typist.scss";

// Idea from https://fossheim.io/writing/posts/react-text-splitting-animations/
// Idea from https://github.com/jstejada/react-typist

/**
 * Brief description of the function here.
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {int} startDelay - Milliseconds before typing animation begins.
 * @param {int} typingDelay - Typing delay in millisecond  between every keystroke of the typing animation.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

export const Typist = ({
  children,
  noEffect = false,
  startDelay = 0,
  typingDelay = 120,
}) => {
  const text = innerText(children);
  return (
    <div className="Typist">
      {noEffect
        ? text
        : text.split("").map(function (char, index) {
            let style = {
              animationDelay: `${startDelay + index * typingDelay}ms`,
            };
            return (
              <span key={index} className="Character" style={style}>
                {char}
              </span>
            );
          })}
    </div>
  );
};

export default Typist;
