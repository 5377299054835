import React from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import "./Read.scss";

export default function Read() {
  return (
    <div className="Read">
      <div className="content is-medium">
        {/* <Document
          file={process.env.REACT_APP_LOCAL_BOOK_URL}
          loading="加載中"
          error="加載失敗"
        /> */}
        <iframe
          title="《細說王安石》"
          className="PDFViewer"
          src="https://drive.google.com/file/d/17n6R8TTypt-prjrdYgC3UC0hL3O2PwQE/preview"
          allow="autoplay"
        ></iframe>
      </div>
    </div>
  );
}
