import React from "react";

import "./Links.scss";

import edcity from "images/logo/edcity.png";
import edconvergence from "images/logo/edconvergence.jpg";
import hkpl from "images/logo/hkpl.jpg";

export default function LinksPage() {
  const links = [
    {
      label: "教育評議會",
      href: "http://www.edconvergence.org.hk/",
      image: edconvergence,
    },
    {
      label: "香港教育城",
      href: "https://www.hkedcity.net/home/",
      image: edcity,
    },
    {
      label: "香港公共圖書館",
      href: "https://www.hkpl.gov.hk/tc/index.html",
      image: hkpl,
    },
  ];
  return (
    <div className="section">
      <div className="Links">
        <div className="content is-medium mx-1">
          <h3>有用連結</h3>
          {links.map((link) => (
            <p>
              <a href={link.href} target="_blank" rel="noopener noreferrer">
                <img src={link.image} alt="" />
                {link.label}
              </a>
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
