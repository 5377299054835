// https://reacttraining.com/react-router/web/example/sidebar
// use this to render in multiple places (navbar and main content)
import About from "pages/About";
import Contact from "pages/Contact";
import Download from "pages/Download";
import Home from "pages/Home";
import Links from "pages/Links";
import Read from "pages/Read";

// if `navLabel` is not `null`, the item will be shown on nav menu
const routes = [
  { navLabel: "首頁", path: "/", exact: true, children: Home },
  { navLabel: "《細說王安石》", path: "/read", exact: true, children: Read },
  { navLabel: "下載專區", path: "/download", exact: true, children: Download },
  { navLabel: "有用連結", path: "/links", exact: true, children: Links },
  { navLabel: "聯絡我們", path: "/contact", exact: true, children: Contact },
  { navLabel: "關於我們", path: "/about", exact: true, children: About },
];
export default routes;
