import React from "react";
import { FaEnvelope, FaUser } from "react-icons/fa";

export default function ContactPage() {
  return (
    <div className="section">
      <h3 className="title is-3">聯絡我們</h3>
      <form action="https://formsubmit.co/info@cnhe-hk.org" method="POST">
        <div className="field">
          <p className="control has-icons-left">
            <input
              type="text"
              name="name"
              className="input is-medium"
              placeholder="姓名"
              required
            />
            <span className="icon is-small is-left">
              <FaUser />
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control has-icons-left">
            <input
              type="email"
              name="email"
              className="input is-medium"
              placeholder="電郵"
              required
            />
            <span className="icon is-small is-left">
              <FaEnvelope />
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control">
            <textarea
              name="message"
              className="textarea is-medium"
              placeholder="你的查詢"
              rows="14"
              required
            ></textarea>
          </p>
        </div>

        <input type="text" name="_honey" className="is-hidden"></input>
        <input type="hidden" name="_template" value="box" />
        <input type="hidden" name="_replyto" />
        <input type="hidden" name="_subject" value="[QEF 王安石傳] 聯絡表格" />

        <button
          type="submit"
          className="button is-primary is-medium is-half-width"
        >
          提交
        </button>
      </form>
    </div>
  );
}
