import React from "react";

export default function AboutPage() {
  return (
    <div className="section">
      <div className="content is-medium">
        <h3>關於我們</h3>
        <div className="columns is-centered">
          <div className="column is-three-fifths-desktop">
            <div className="section">
              <h4>「王安石傳」計劃簡介</h4>
              <p>
                「王安石傳」（QEF編號：2019/0632）乃優質教育基金所贊助的計劃，計劃旨在以小說形式，介紹歷史人物王安石，以推廣中國歷史及中國文化。透過小說的內容，讓學生從語文領域方面，提升學生的中文能力；從小說背景，學生認識中國歷史知識；從小說主人翁王安石的風骨及處事作風，學生可學習到正確的價值觀及學生正向發展。
              </p>
            </div>
            <div className="section">
              <h4>國史教育中心簡介</h4>
              <p>
                「國史教育中心（香港）」於2018年中正式成立。中心開幕迎來政務司司長張建忠先生及中聯辦副主任譚鐵牛院士為主禮嘉賓，聯同前中文大學校長劉遵義教授、中心主席何志豪先生、校監丁新豹教授及何漢權校長共同為中心主持開
                幕儀式。
              </p>
              <p>
                歷史發展象徵國族血脈的延續，中心冀望本張橫渠先生「為天地立心，為生民立命，為往聖繼絕學，為萬世開太平」之精神，倡議建構民間公共平台，堅持
                國史教育理念，凝聚社會各界共識，向年青一代、學生、老師及公眾人士，傳承中華民族文化精萃。
              </p>
              <p>
                本中心深信「國民教育的根本實源於國史教育」，活動以四個面向（4Es）為原則：教育（Education）、參與（Engagement）、體驗（Experience）、表揚（Exposition），設計了一系列多元化的國史教育活動予不同層面的對象，務求凝聚社會各界，共同賡續中國歷史文化。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
