import React from "react";

import "./Footer.scss";

import email from "images/icons/email.png";
import fax from "images/icons/fax.png";
import mailbox from "images/icons/mailbox.png";
import phone from "images/icons/phone.png";
import website from "images/icons/website.png";

export default function Footer() {
  return (
    <div className="Footer">
      <footer className="footer">
        <div className="columns is-multiline">
          <div className="column is-full">
            <div className="flex-row justify-center">
              <img src={mailbox} className="is-larger" alt="" />
              <span>
                <span>
                  國史教育中心(香港) Centre of National History Education (Hong
                  Kong)
                </span>
                <br />
                香港西營盤德輔道西 246 號東慈商業中心 1901-1902 室
                <br />
                Room 1901-1902, Tung Che Commercial Centre, 246 Des Voeux Road
                West, Sai Ying Pun, Hong Kong
              </span>
            </div>
          </div>
          <div className="column is-half-tablet is-one-quarter-desktop">
            <div className="flex-row justify-center">
              <img src={phone} alt="" />
              <span>3468 5980</span>
            </div>
          </div>
          <div className="column is-half-tablet is-one-quarter-desktop">
            <div className="flex-row justify-center">
              <img src={fax} alt="" />
              <span>3586 0209</span>
            </div>
          </div>
          <div className="column is-half-tablet is-one-quarter-desktop">
            <div className="flex-row justify-center">
              <img src={email} alt="" />
              <a href="mailto:info@cnhe-hk.org">info@cnhe-hk.org</a>
            </div>
          </div>
          <div className="column is-half-tablet is-one-quarter-desktop">
            <div className="flex-row justify-center">
              <img src={website} alt="" />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cnhe-hk.org"
              >
                www.cnhe-hk.org
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
