import React from "react";
import Zoom from "react-reveal/Zoom";

import Typist from "components/Typist";

import "./Home.scss";
import was_sketch from "images/was_sketch.jpg";

export default function HomePage() {
  return (
    <div className="Home">
      <div className="content py-5">
        <div className="columns is-desktop is-vcentered">
          <div className="column">
            <Zoom right delay={1500} duration={4000}>
              <img className="was_sketch" src={was_sketch} alt="王安石白描" />
            </Zoom>
          </div>
          <div className="column">
            <div className="TextBox has-text-left has-text-weight-medium px-5">
              <div className="has-text-centered mx-4">《細說王安石》簡介</div>
              <Typist noEffect={false} startDelay={500} typingDelay={120}>
                <div>
                  王安石是中國歷史上極具爭議的人物，作為一代名相，他被《宋史》列入「奸臣傳」，被貶為古今第一小人，禍國殃民，遺臭萬年。但也有人稱讚他是超越古今的偉人，他表現出跨越時代的宏觀經濟學思想，比西方早一千年就提出一套完善經濟學理論。為何同一個人會有這樣南轅北轍的評價呢？本小說圍繞熙寧變法的始末進行探索，嘗試從不同人物關係線勾勒出王安石的具體面貌，讓讀者從不同角度去認識這位歷史人物，並自行分析、評價他的功過與歷史地位。
                </div>
              </Typist>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
