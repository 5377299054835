import React from "react";
import { FaRegFilePdf } from "react-icons/fa";

import ChapterDownload from "components/ChapterDownload";
import downloads from "./downloads.json";
import "./Download.scss";

export default function Download() {
  return (
    <div className="section">
      <div className="Download ">
        <div className="content is-medium">
          <h3>電子書</h3>
          <a
            href="https://drive.google.com/uc?export=download&id=17n6R8TTypt-prjrdYgC3UC0hL3O2PwQE"
            target="_blank"
            rel="noreferrer"
          >
            <div className="is-flex is-align-items-center  is-justify-content-center">
              <FaRegFilePdf style={{ fill: "#db3a14" }} className="mr-1" />
              下載《細說王安石》電子書
            </div>
          </a>
          <h3>工作紙</h3>
          {downloads.map((item) => (
            <ChapterDownload chapter={item} key={item.title} />
          ))}
        </div>
      </div>
    </div>
  );
}
