import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import routes from "routes";

import qef from "images/logo/qef_s.png";
import bannerImg from "images/banner.png";

import "./Header.scss";

const NavbarItem = ({ navLabel, path, ...props }) => (
  <NavLink
    to={path}
    exact
    className="navbar-item"
    activeClassName="is-active"
    {...props}
  >
    {navLabel}
  </NavLink>
);

// [Bulma navbar with two rows](https://stackoverflow.com/a/47438756)
export default function Header() {
  const [active, setActive] = useState(false);
  const toggleMenu = () => setActive(!active);
  const closeMenu = () => setActive(false);

  return (
    <div className="Header">
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <span className="is-flex is-align-items-center">
            <img src={qef} className="qefImg" alt="" title="優質教育基金" />
            <img
              src={bannerImg}
              className="bannerImg"
              alt=""
              title="王安石傳"
            />
          </span>

          <span
            role="button"
            className={`navbar-burger burger ${active ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded={active}
            data-target="navMenu"
            onClick={toggleMenu}
          >
            {/* the hamburger */}
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
        </div>

        <div className={`navbar-menu ${active ? "is-active" : ""}`}>
          <div className="navbar-start">
            {routes.map(
              (route) =>
                route.navLabel && (
                  <NavbarItem
                    {...route}
                    key={route.navLabel}
                    onClick={closeMenu}
                  />
                )
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
